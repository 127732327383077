import React from 'react';
import classnames from 'classnames/bind';
import styles from './MapContent.module.scss';

const cx = classnames.bind(styles);
const MapContent = ({ children, title, map, mapFloat }) => {
    const textClasses = cx(styles.textContent, { 
        left: mapFloat === 'right', 
        right: mapFloat === 'left' 
    });
    const mapClasses = cx (styles.mapContent, styles[mapFloat]);

    return (
        <div className={styles.container}>
            <div className={styles.containerItem}>
                <div className={textClasses}>
                    <h2 className={styles.heading}>{title}</h2>
                    {children}
                </div>
                <div className={mapClasses}>
                    {map}
                </div>
            </div>
        </div>
    );
};
export default MapContent;