import React from 'react';
import Splash from '../../components/splash/Splash';
import MapContent from '../../components/content/MapContent';
import styles from './Contact.module.scss';

const Contact = () => {
    const map = (<iframe 
        id='map' 
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d996.3470325852487!2d9.88662427937326!3d53.56265924293375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b185b48df0b0cd%3A0xa8faf33e4a99af76!2sEbertallee+3%2C+22607+Hamburg%2C+Germany!5e0!3m2!1sen!2suk!4v1563107568551!5m2!1sen!2suk'
        style={{border: 0}} 
        allowFullScreen=''
        width='400' 
        height='300'
        frameBorder='0' 
    />);
    return (
        <React.Fragment>
            <Splash>
                <h1 className={styles.splashHeading}>Kontakt</h1>
            </Splash>
            <MapContent mapFloat='right' title='Kontaktieren Sie uns' map={map}>
                <h2>Adresse</h2>
                <span className={styles.address}>
                    <p>Ebertallee 3</p>
                    <p>22607</p>
                    <p>Hamburg</p>
                </span>
                <p>Tel.: <a href='tel:0408997643'>040/ 899 76 43</a></p>
                <p>Email: <a href="mailto:dentalinspiration@hamburg.de?Subject=Dentallabor%20Anfrage" target="_top">dentalinspiration@hamburg.de</a></p>
                <h2>Im Labor</h2>
                <ul>
                    <li>Montag: 08:00 - 16:00</li>
                    <li>Dienstag: 08:00 - 16:00</li>
                    <li>Mittwoch: 08:00 - 16:00</li>
                    <li>Donnerstag: 08:00 - 16:00</li>
                    <li>Freitag: 08:00 - 16:00</li>
                </ul>
                <p>und nach Vereinbarung</p>
            </MapContent>
        </React.Fragment>
    );
};

export default Contact;